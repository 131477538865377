// App.js
import React, {useContext, useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './pages/Home';
import Navbar from './components/staticComponents/Navbar';
import ArticleDetails from "./pages/ArticleDetails";
import SideMenu from "./components/staticComponents/SideMenu";
import SideBar from "./components/staticComponents/SideBar";
import ArticleSources from "./pages/ArticleSources";
import RODO from "./pages/RODO";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import WebsiteRegulations from "./pages/WebsiteRegulations";
import {ThemeContext} from "./components/context/ThemeContext";
import NotFoundError from "./components/technicalComponents/NotFoundError";
import '../src/sass/layout.css';

function App() {
    const [sortOption, setSortOption] = useState('latest');
    const [category, setCategory] = useState('');
    const [sources, setSources] = useState([]);
    const [selectedSource, setSelectedSource] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
    const [isSideMenuExpanded, setIsSideMenuExpanded] = useState(false);
    const {theme, toggleTheme} = useContext(ThemeContext);
    const [articlesCache, setArticlesCache] = useState({});
    const [articles, setArticles] = useState([]);
    const [offset, setOffset] = useState(0);
    const [lastSeenLatest, setLastSeenLatest] = useState(null);
    const [lastSeenPopular, setLastSeenPopular] = useState(null);
    const [hasMoreArticles, setHasMoreArticles] = useState(true);

    const updateArticles = (newArticles) => {
        setArticles(newArticles);
    };

    const updateOffset = (newOffset) => {
        setOffset(newOffset);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 800);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleToggleSideMenuExpand = () => {
        setIsSideMenuExpanded(!isSideMenuExpanded);
    };

    const handleCategoryClick = (category) => {
        setCategory(category);
        setIsSideMenuExpanded(false);
    };

    return (
        <Router>
            <div className="layout">
                {isMobile ? null : (
                    <SideMenu
                        onSelectCategory={handleCategoryClick}
                        currentCategory={category}
                        currentSortOption={sortOption}
                        onSelectSortOption={setSortOption}
                        sources={sources}
                        onSelectSource={setSelectedSource}
                        selectedSource={selectedSource}
                        isExpanded={isSideMenuExpanded}
                        toggleMenu={setIsSideMenuExpanded}
                        onToggleTheme={toggleTheme}
                        theme={theme}
                        setLastSeenLatest={setLastSeenLatest}
                    />
                )}
                <div className="mainContent">
                    <Navbar
                        onSelectSortOption={setSortOption}
                        currentSortOption={sortOption}
                        onToggleSideMenuExpand={handleToggleSideMenuExpand}
                        currentCategory={category}
                        selectedSource={selectedSource}
                        onSelectSource={setSelectedSource}
                    />
                    {isMobile ? (
                        <SideMenu
                            onSelectCategory={handleCategoryClick}
                            currentCategory={category}
                            currentSortOption={sortOption}
                            onSelectSortOption={setSortOption}
                            sources={sources}
                            onSelectSource={setSelectedSource}
                            selectedSource={selectedSource}
                            isExpanded={isSideMenuExpanded}
                            toggleMenu={setIsSideMenuExpanded}
                            onToggleTheme={toggleTheme}
                            theme={theme}
                            setLastSeenLatest={setLastSeenLatest}
                        />
                    ) : null}
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <Home
                                    sortOption={sortOption}
                                    category={category}
                                    articlesCache={articlesCache}
                                    setArticlesCache={setArticlesCache}
                                    articles={articles}
                                    setArticles={updateArticles}
                                    offset={offset}
                                    setOffset={updateOffset}
                                    hasMoreArticles={hasMoreArticles}
                                    setHasMoreArticles={setHasMoreArticles}
                                    lastSeenLatest={lastSeenLatest}
                                    setLastSeenLatest={setLastSeenLatest}
                                    lastSeenPopular={lastSeenPopular}
                                    setLastSeenPopular={setLastSeenPopular}
                                />
                            }
                        />
                        <Route path="/post/:id" element={<ArticleDetails/>}/>
                        <Route
                            path="/post/:id/sources"
                            element={<ArticleSources setSources={setSources} selectedSource={selectedSource}/>}
                        />
                        <Route path="/rodo" element={<RODO/>}/>
                        <Route path="/polityka-prywatnosci" element={<PrivacyPolicy/>}/>
                        <Route path="/regulamin-serwisu" element={<WebsiteRegulations/>}/>
                        <Route path="*" element={<NotFoundError/>}/>
                    </Routes>
                </div>
                <SideBar/>
            </div>
        </Router>
    );
}

export default App;
