// Home.js
import React, {useEffect, useState} from 'react';
import ArticleList from '../components/articleComponents/ArticleList';
import {CapacitorHttp} from '@capacitor/core';
import '../sass/home.css';
import Loader from "../components/technicalComponents/Loader";
import FetchError from "../components/technicalComponents/FetchError";
import {Helmet} from "react-helmet";

function Home({
                  sortOption,
                  category,
                  articlesCache,
                  setArticlesCache,
                  articles,
                  setArticles,
                  offset,
                  setOffset,
                  hasMoreArticles,
                  setHasMoreArticles,
                  lastSeenLatest,
                  setLastSeenLatest,
                  lastSeenPopular,
                  setLastSeenPopular,
              }) {
    const [loading, setLoading] = useState(false);

    const schemaData = {
        "@context": "https://schema.org",
        "@type": "NewsMediaOrganization",
        "name": "Verita News",
        "url": "https://verita.news",
        "logo": "https://verita.news/logo192.png",
        "description": "Verita News to inteligentny agregator wiadomości, który podsumowuje najważniejsze wiadomości w 5 punktach.",
        "sameAs": [
            "https://www.facebook.com/profile.php?id=61553263686778",
            "https://www.tiktok.com/@verita.news",
            "https://www.instagram.com/verita.news/"
        ]
    };

    const fetchArticles = async (newOffset) => {
        setLoading(true);

        let url = sortOption === 'popular'
            ? `https://cms.verita.news/v1/feed/news/${sortOption}?limit=15&offset=${newOffset}`
            : `https://cms.verita.news/v1/feed/news/${sortOption}?${category ? `category=${category}&` : ''}limit=15&offset=${newOffset}`;

        if (sortOption === 'popular' && lastSeenPopular) {
            url += `&lastSeen=${lastSeenPopular}`;
        } else if (sortOption === 'latest' && lastSeenLatest) {
            url += `&lastSeen=${lastSeenLatest}`;
        }

        const options = {url};

        try {
            const response = await CapacitorHttp.get(options);
            const newArticles = response.data.newsPostsFeed || [];

            const updatedArticles = newOffset === 0 ? newArticles : [...articles, ...newArticles];
            setArticles(updatedArticles);

            const cacheKey = `${sortOption}-${category}`;
            setArticlesCache((prevCache) => ({
                ...prevCache,
                [cacheKey]: updatedArticles,
            }));

            if (newArticles.length > 0 && sortOption === 'popular') {
                const lastArticleId = newArticles[newArticles.length - 1]?.itemId;
                setLastSeenPopular(lastArticleId);
            }

            if (newArticles.length > 0 && sortOption === 'latest') {
                const lastArticleId = newArticles[newArticles.length - 1]?.itemId;
                setLastSeenLatest(lastArticleId);
            }

            if (newArticles.length < 15) {
                setHasMoreArticles(false);
            }

        } catch (error) {
            console.error('Error fetching articles:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const cacheKey = `${sortOption}-${category}`;

        if (articlesCache[cacheKey]?.length > 0) {
            setArticles(articlesCache[cacheKey]);
            setHasMoreArticles(articlesCache[cacheKey].length >= 15);
        } else {
            if(sortOption === 'latest') {
                setLastSeenLatest(null);
            }
            if(sortOption === 'popular') {
                setLastSeenPopular(null);
            }
            setArticles([]);
            setOffset(0);
            setHasMoreArticles(true);
            fetchArticles(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortOption, category]);

    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 1200 &&
                !loading &&
                hasMoreArticles
            ) {
                const newOffset = offset + 15;
                setOffset(newOffset);
                fetchArticles(newOffset);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset, loading, hasMoreArticles]);

    return (
        <main>
            <div>
                <Helmet>
                    <title>Verita News – Najważniejsze wiadomości w 5 punktach</title>
                    <meta property="og:title" content="Verita News" />
                    <meta property="og:description" content="Najważniejsze wiadomości w 5 punktach. Szybko, konkretnie i bez zbędnych treści. Sprawdź, co wydarzyło się dziś w Polsce i na świecie!" />
                    <meta property="og:image" content="https://storage.googleapis.com/image.verita.news/main.png" />
                    <meta property="og:type" content="website" />

                    <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
                </Helmet>
            </div>
            <ArticleList articles={articles} sortOption={sortOption}/>
            {loading && <Loader/>}
            {!loading && articles.length === 0 && <FetchError/>}
        </main>
    );
}

export default Home;
