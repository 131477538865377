import React, {useState, useEffect} from "react";
import Category from "../technicalComponents/Category";
import Timer from "../technicalComponents/Timer";
import {useNavigate} from "react-router-dom";
import '../../sass/articleWithoutImageVersion.css';
import arrow from '../../img/icons/greenArrow.svg';

const ArticleWithoutImageVersion = ({post}) => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 800);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleRedirect = () => {
        navigate(`/post/${post.itemId}`);
    };

    const handleViewAllSources = (event) => {
        event.stopPropagation();
        navigate(`/post/${post.itemId}/sources`);
    };
    const getVerbForm = (number) => {
        const lastTwoDigits = number % 100;
        const lastDigit = number % 10;

        if (number === 1) {
            return 'Pisze';
        } else if (lastTwoDigits >= 12 && lastTwoDigits <= 14) {
            return 'Pisze';
        } else if (lastDigit >= 2 && lastDigit <= 4) {
            return 'Piszą';
        } else {
            return 'Pisze';
        }
    };

    const getNounForm = (number) => {
        const lastTwoDigits = number % 100;
        const lastDigit = number % 10;

        if (number === 1) {
            return 'źródło';
        } else if (lastTwoDigits >= 12 && lastTwoDigits <= 14) {
            return 'źródeł';
        } else if (lastDigit >= 2 && lastDigit <= 4) {
            return 'źródła';
        } else {
            return 'źródeł';
        }
    };

    return (
        <article>
            <div className="articleContent" onClick={handleRedirect}>
                <div className="articleContent-mainInfo">
                    <Category category={post.category}/>
                    <h2>{post.title}</h2>
                    <Timer creationDate={post.creationDate} customStyle={!isMobile ? "withMargin" : ""}/>
                    <p className="articleContent-summary">{post.summary}</p>
                    <p className="articleContent-summary-seeMore">Zobacz więcej</p>
                    <p className="articleContent-text">
                        {getVerbForm(post.sourceInfo.numberOfSource)} o tym <span>{post.sourceInfo.numberOfSource}</span> {getNounForm(post.sourceInfo.numberOfSource)}:
                    </p>
                </div>
                <div className="articleContent-sources">
                    {post.sourceInfo.sources.slice(0, 4).map((source) => {
                        const formattedSourceName = source.sourceName
                            .toLowerCase()
                            .replace(/ /g, '');
                        const sourceImage = `https://storage.googleapis.com/image.verita.news/sources/${formattedSourceName}.png`;
                        return (
                            <div className="articleContent-sources-element" key={source.sourceId}>
                                <a href={source.redirectLink} target="_blank" rel="noopener noreferrer">
                                    <div className="articleContent-sources-element-companyWrapper">
                                        <img className="companyLogo" src={sourceImage} alt={`${source.sourceName} logo`}
                                             onError={(e) => {
                                                 e.target.onerror = null;
                                                 e.target.src = "https://storage.googleapis.com/image.verita.news/sources/notFound.png";
                                             }}/>
                                        <p className="articleContent-sources-element__sourceName">{source.sourceName}</p>
                                    </div>
                                    <h4 className="articleContent-sources-element__title">{source.title}</h4>
                                </a>
                            </div>
                        );
                    })}
                    {isMobile && (
                        <div className="articleContent-sources-element-showAll" key="show all" onClick={handleViewAllSources}>
                            <div className="articleContent-sources-element-showAll-banner">
                                <p>Zobacz wszystkie materiały</p>
                                <img src={arrow} alt="arrow icon"/>
                            </div>
                        </div>
                    )}
                </div>
                <div className="showMore-wrapper">
                    <p className="showMore-text">Dowiedz się więcej</p>
                    <img className="showMore-img" src={arrow} alt="arrow icon"/>
                </div>
            </div>
        </article>
    );
};

export default ArticleWithoutImageVersion;
