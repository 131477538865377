import React, {useState, useEffect, useContext} from 'react';
import { useParams } from 'react-router-dom';
import { CapacitorHttp } from '@capacitor/core';
import '../sass/articleSources.css';
import Loader from "../components/technicalComponents/Loader";
import placeHolder from '../img/ErrorFetchingImage.png';
import placeHolderDark from '../img/ErrorFetchingImageDark.png';
import {ThemeContext} from "../components/context/ThemeContext";
import NotFoundError from "../components/technicalComponents/NotFoundError";
import {Helmet} from "react-helmet";

const ArticleSources = ({ setSources, selectedSource }) => {
    const { id } = useParams();
    const [article, setArticle] = useState(null);
    const [error, setError] = useState(false);
    const {theme} = useContext(ThemeContext);
    const placeHolderImage = theme === 'light' ? placeHolder : placeHolderDark;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchArticle = async () => {
            const options = {
                url: `https://cms.verita.news/v1/post/news/${id}`,
            };

            try {
                const response = await CapacitorHttp.get(options);

                if (response.status === 200) {
                    const allSources = response.data.sourceInfo.sources;

                    const uniqueSources = allSources.filter(
                        (source, index, self) =>
                            index === self.findIndex((s) => s.sourceName === source.sourceName)
                    );

                    setSources(uniqueSources);

                    setArticle({
                        ...response.data,
                        sourceInfo: {
                            sources: selectedSource
                                ? allSources.filter(
                                    (source) => source.sourceName === selectedSource
                                )
                                : allSources,
                        },
                    });
                } else {
                    setError(true);
                }
            } catch (error) {
                setError(true);
                console.error('Error fetching article:', error);
            }
        };

        fetchArticle();
    }, [id, setSources, selectedSource]);

    if (error) {
        return <NotFoundError />;
    }
    if (!article) {
        return <Loader/>;
    }

    return (
        <>
            <div>
                <Helmet>
                    <title>{article.title}</title>
                    <meta property="og:title" content={article.title}/>
                    <meta property="og:description" content={article.summary[0]} />
                    <meta property="og:image" content="https://storage.googleapis.com/image.verita.news/main.png" />
                    <meta property="og:type" content="article" />

                    <script type="application/ld+json">{JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "NewsArticle",
                        "headline": `${article.title}`,
                        "image": ["https://storage.googleapis.com/image.verita.news/main.png"],
                        "datePublished": `${article.creationDate}`,
                        "dateModified": `${article.modificationDate}`,
                        "author": { "@type": "Organization", "name": "Verita News" },
                        "publisher": { "@type": "Organization", "name": "Verita News" }
                    })}</script>
                </Helmet>
            </div>
            <section className="articleSources-container">
                <div className="articleSources-list">
                    {article.sourceInfo.sources.map((source) => {
                        const formattedSourceName = source.sourceName
                            .toLowerCase()
                            .replace(/ /g, '');
                        const sourceImage = `https://storage.googleapis.com/image.verita.news/sources/${formattedSourceName}.png`;
                        return (
                            <article className="articleSources-item" key={source.sourceId}>
                                <a href={source.redirectLink} target="_blank" rel="noopener noreferrer">
                                    <div className="articleSources-item-info">
                                        <div className="articleSources-item-info-companyWrapper">
                                            <img className="companyLogoSources" src={sourceImage} alt={`${source.sourceName} logo`} onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = "https://storage.googleapis.com/image.verita.news/sources/notFound.png";
                                            }}/>
                                            <p className="articleSources-item-info__sourceName">{source.sourceName}</p>
                                        </div>
                                        <h4 className="articleSources-item-info__title">{source.title}</h4>
                                    </div>
                                    <img className="articleSources-item-image" src={source.sourceImageUrl} alt={source.sourceName} onError={(e ) => {
                                        e.target.onerror = null;
                                        e.target.src = placeHolderImage
                                    }} />
                                </a>
                            </article>
                        );
                    })}
                </div>
            </section>
        </>
    );
};

export default ArticleSources;
